<template>
  <section>
    <BasePicListPage
      :cols="cols"
      :formField="formField"
      :status="1"
      :query="query"
      hasCheckAllData4
      :isSearch="checkPermission(['externaladmin:user:picManage:imageList'])"
    >
      <template v-slot:batchOperation="{ sup_this }">
        <BatchOperation
          class="batch-operation-wrapper"
          style="margin-top: 10px"
          v-p="['externaladmin:user:user:picManage:batchDisable']"
        >
          <div class="g-left-operation">
            <BulkDisable @successHandler="sup_this.clearAllSelectedData" :sup_this="sup_this" />
          </div>
        </BatchOperation>
      </template>

      <template v-slot="{ sup_this }">
        <el-table-column fixed="right" align="center" label="操作" width="100px">
          <template slot-scope="{ row }">
            <Disable :data="row" :sup_this="sup_this" v-p="['externaladmin:user:picManage:disable']"></Disable>
          </template>
        </el-table-column>
      </template>
    </BasePicListPage>
  </section>
</template>

<script>
import BasePicListPage from '../basePicListPage'
import { galleryField as formField } from './field'
import { imageListCols as cols } from './cols'
import Disable from '../module/disable'
import BulkDisable from '../module/bulkDisable'

export default {
  components: {
    BasePicListPage,
    Disable,
    BulkDisable
  },
  data() {
    return {
      cols,
      formField,
      query: {
        isPublic: false
      }
    }
  }
}
</script>

<style></style>
